footer {
    height: 6rem;
    line-height: 6rem;
    text-align: right;
    color: #9fa2a8;
}

.disclaimer {
    display: inline-block;
    vertical-align: middle;
    padding-left: 3rem;
    font-size: 1.3rem;
    line-height: 1.3;
}

