header {
    height: 6rem;
    line-height: 6rem;
    text-align: right;
    background: #005999;

}

.logo {
    height: 6rem;
    line-height: 6rem;
    font-size: 0;

    &__img{
        display: inline-block;
        vertical-align: middle;
        height: 2.4rem;
    }
}


.header__login{
    display: inline-block;
    vertical-align: middle;
    padding: 1rem 2rem;
    font-size: 1.3rem;
    line-height: 1.3;
    text-transform: uppercase;
    color: #fff;
    text-decoration: none;
    border-radius: 2px;
    background: transparent;
    transition: all 0.3s linear;

    &:hover{
        background: #0068b3;
    }
}

.header__register{
    display: inline-block;
    vertical-align: middle;
    margin-left: 30px;
    margin-right: 30px;
    padding: 1rem 2rem;
    font-size: 1.3rem;
    line-height: 1.3;
    text-transform: uppercase;
    color: #fff;
    text-decoration: none;
    border-radius: 2px;
    background-color: #7fba2f;
    transition: all 0.3s linear;

    &:hover{
        background: #84cb20;
    }
}
