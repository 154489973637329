* {
    box-sizing: border-box;
}

[role="button"] {
    cursor: pointer;
}

html {
    height: 100%;
    font-size: 62.5%;
    direction: ltr;

    &[lang='ar']{
        direction: rtl;
    }
}

body {
    height: 100%;
    font-family: Tahoma, Helvetica, sans-serif;
    font-size: 1rem;
    line-height: initial;
    color: #fff;
    background: #293951;
    overflow: hidden;
}

.layout {
    display: flex;
    height: 100vh;
    flex-direction: column;
}

.wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    max-width: 1366px;
    margin: auto;
    padding-left: 15px;
    padding-right: 15px;
}


.app {
    flex-grow: 1;

    iframe{
        width: 100%;
        border: none;
    }
}
